import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { StaticQuery, Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Img from 'gatsby-image'


class DesignPage extends React.Component {
  
    render(){

      return(
        <Layout containerClass="design">
            <SEO title="Design / Drafting" />
            <section className="hero">
              <div className="uk-cover-container height-fixed">
                <Img fluid={this.props.data.header.childImageSharp.fluid} className="uk-height-viewport"/>
                <div class="uk-width-1-1 uk-height-viewport custom-overlay">
                  <div class="uk-overlay uk-position-bottom"> 
                  <h1 className="uk-text-left">Design & Drafting</h1>                                
                  </div>
                  <AnchorLink className="more" href="#main">
                    <FontAwesomeIcon icon="chevron-down" />
                  </AnchorLink>
                </div>
                
                  
                
              </div>
            </section>

            <section className="section intro pad-200" id="main">
              <div className="uk-container uk-container-center uk-height-1-1">
                <div className="uk-flex uk-flex-wrap uk-flex-middle uk-flex-center uk-height-1-1">
                    <div className="uk-width-1-1 uk-width-3-4@l">
                      <h2 class="text-white">
                        We use 3D & 2D software for our own in-house projects and we offer this as a stand alone service to our clients. 
                        We can take architectural drawings and convert them into workshop drawings for fabrication. 
                        We can also create .DXF files required for profile, laser and water jet cutting processes
                      </h2>
                    </div>
                  </div>
                </div>
            </section>    
            <div className="gallery">
                <div className="uk-flex uk-flex-wrap uk-flex-middle ">
                    <div className="uk-width-1-2@l uk-cover-container">
                      <Img fluid={this.props.data.one.childImageSharp.fluid}/>
                    </div>
                    <div className="uk-width-1-2@l uk-cover-containerr">
                      <Img fluid={this.props.data.two.childImageSharp.fluid}/>
                    </div>
                  </div>
            </div>                
        </Layout>
      )
    }
}

export default DesignPage

export const query = graphql`
  query designQuery {
    header:file(relativePath: { eq: "new/PH07.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid
            }
          }
    }
    one:file(relativePath: { eq: "new/PH10.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
              src
              srcSet
              aspectRatio
              sizes
              base64
            }
          }
    }
    two:file(relativePath: { eq: "new/PH09.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
              src
              srcSet
              aspectRatio
              sizes
              base64
            }
          }
    }
  }
`;